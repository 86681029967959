import React from "react";
import { Typography, Box, ButtonBase } from "@mui/material";
import "./App.css";
import { ReactComponent as WaivlengthLogo } from "./waivlength-logo.svg";
import twitterLight from "website/icons/twitter.svg";
import LinkedinLight from "website/icons/linkedin.svg";
import githubLight from "website/icons/github.svg";
import MediumLight from "website/icons/medium.svg";

function App() {
  return (
    <Box className="app-container">
      <Box className="content">
        <Box className="landing-container">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "92px",
              width: "92px",
            }}
          >
            <WaivlengthLogo />
          </Box>
          <Box sx={{ width: "60%" }}>
            <Typography className="typography-h2 ">
              Waivlength: Revolutionizing Web3 Community Management
            </Typography>
          </Box>
          <ButtonBase
            className="StyledButtonBase"
            disableRipple
            sx={{
              display: "flex",
              flexFlow: "nowrap",
              alignItems: "center",
              borderRadius: "9999px",
              padding: "10px 22px",
              mt: "8px",
            }}
            onClick={() => window.open("https://waivlength.app/", "_blank")}
          >
            <Typography className="text-color-v6 typography-h1">
              Launch App
            </Typography>
          </ButtonBase>
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                paddingTop: "16px"
              }}
            >
              <a
                href="https://github.com/Waivlength-io"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={githubLight} alt="Github" />
              </a>
              <a
                href="https://medium.com/@waivlengthdapp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={MediumLight} alt="Medium" />
              </a>
              <a
                href="https://twitter.com/waivlength_io"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={twitterLight} alt="Twitter" />
              </a>
              <a
                href="https://www.linkedin.com/company/waivlengthapp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={LinkedinLight} alt="Linkedin" />
              </a>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default App;
